<template>
  <div>
    <CCard class="col-md-12">
      <CRow class="p-3">
       <h4 class="color-light ml-2 page_title"><span class="ul_text">IP </span>WHITELIST</h4>
      </CRow>
      <CCardBody>
        <CRow>
              <CInput
                class="col-md-10"
                label="IP Whitelist"
                type="text"
                size="sm"
                v-model="ip.ip_whitelist"
                required
                placeholder=""
                :state="validation"
                append="X"
              ></CInput>
        </CRow>
        <CRow class="col-md-8">
            <p style="font-size: 12px;">
              Comma separated list of IPs in <a href=""> CIDR notation </a>. By
              setting this option, fraudAvoid will perform detections as
              usual, but won't block any clicks from the IP addresses listed
              above. This is useful for whitelisting your corporate office or
              data center IPs so that you can test fraudAvoid successfully.
            </p>
        </CRow>
        <CRow class="col-md-10">
        <label> Enable / Disable IP Whitelist check</label>
          <CSwitch
              class="ml-2"
              color="success"
              :checked="ip.ipcheck"
              shape="pill"
              @update:checked="ipSwitch"
            />
            
            <!-- <CInputCheckbox
              label="I recognise that any click which falls in the range I have just
              configured won't be blocked. Clicks will be scanned as
              usually."
              value="true"
              unchecked-value="false"
              :checked.sync="ip.ipcheck"
            >
            </CInputCheckbox> -->
        </CRow>
        <label class="mt-2">I recognise that any click which falls in the range I have just
              configured won't be blocked. Clicks will be scanned as
              usually.</label>
          <!-- <b-form-invalid-feedback
            style="margin-left:160px;"
            :state="validation"
            >Please add a name for the traffic source which will help you
            identify it later</b-form-invalid-feedback> -->
        <CRow class="mt-3">
          <CButton type="submit" color="success" v-on:click="onSubmit">Save</CButton
          >
        </CRow>
      </CCardBody></CCard
    >
  </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import SettingService from "./../../services/settingControlService";
export default {
  data() {
    return {
      ip: { ip_whitelist: "", ipcheck: null }
    };
  },
  async mounted() {
    if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
        }
    else{
    try {
      let response = await SettingService.ipget();

      if (response.message) {
        this.ip.ip_whitelist = response.message.ipwhlist.toString();
        this.ip.ipcheck = response.message.ipcheck;
      }
    } catch (e) {
      console.log('connection error')
      // this.errorNotify("Error", "connection error", "danger");
    }
    }
  },
  computed: {
    validation() {
      //   if (this.mainObject.offername == "") {
      //     return false;
      //   }
    },
  },
  methods: {
    ipSwitch(){
      if (this.ip.ipcheck == true) {
          this.ip.ipcheck = false;
        } else {
          this.ip.ipcheck = true;
        }
    },
    async onSubmit() {
      try {
        let ip_data = {
          ipwhlist: this.ip.ip_whitelist,
          ipcheck: this.ip.ipcheck
        };
        let response = await SettingService.ip(ip_data);
         if(response.result){
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
        else{
          Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
      } catch (e) {
          // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
        // this.$emit('ready', false)
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
  },
};
</script>

<style>
label {
  text-align: left;
  font-weight: 400;
  color: black;
  font-size: 12px;
}
</style>
